<template>
  <div class="custom-bg-secondary workWithUs">
    <b-breadcrumb class="container mx-auto" style="margin-bottom: 0; border-radius: 0">
      <!-- <b-breadcrumb-item :to="{ name: 'Home' }">
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
      </b-breadcrumb-item> -->
      <b-breadcrumb-item active>{{ $t("navBar.label.workWithPASH") }}</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="workWithUs_bg">
      <b-container>
        <div class="text-white has-text">
          <h3>{{ $t("navBar.label.DifferentWaysOfWorkingWithPash") }}</h3>
        </div>

        <div class="box-wrapper">
          <div class="work-box-wrapper">
            <b-link :to="{ name: 'Sell' }" style="text-decoration: none">
              <b-card class="text-black work-box">
                <h5>{{ $t("navBar.label.sellers") }}</h5>
                <!-- <span class=""
                    >Duis et odio in metus malesuada tincidunt eget eu odio. Duis sed
                    velit turpis.
                  </span> -->
              </b-card>
            </b-link>
          </div>
          <div class="work-box-wrapper">
            <b-link
              :to="{
                name: 'Contact',
                params: { type: 'inspection-agents' },
              }"
              style="text-decoration: none"
              class="text-black"
            >
              <b-card class="text-black work-box">
                <h5>{{ $t("navBar.label.inspectionAgents") }}</h5>
                <!-- <span
                    >Duis et odio in metus malesuada tincidunt eget eu odio. Duis sed
                    velit turpis.
                  </span> -->
              </b-card>
            </b-link>
          </div>
          <div class="work-box-wrapper">
            <b-link
              :to="{
                name: 'Contact',
                params: { type: 'freight-forwarders' },
              }"
              style="text-decoration: none"
            >
              <b-card class="text-black work-box">
                <h5>{{ $t("navBar.label.freightForwarders") }}</h5>
                <!-- <span
                    >Duis et odio in metus malesuada tincidunt eget eu odio. Duis sed
                    velit turpis.
                  </span> -->
              </b-card>
            </b-link>
          </div>
          <div class="work-box-wrapper">
            <b-link
              :to="{
                name: 'Contact',
                params: { type: 'customs-brokers' },
              }"
              class="h-100 d-block"
              style="text-decoration: none"
            >
              <b-card class="text-black work-box">
                <h5>{{ $t("navBar.label.customsBrokers") }}</h5>
                <!-- <span
                    >Duis et odio in metus malesuada tincidunt eget eu odio. Duis sed
                    velit turpis.
                  </span> -->
              </b-card>
            </b-link>
          </div>
          <div class="work-box-wrapper">
            <b-link
              :to="{
                name: 'Contact',
                params: { type: 'sales-representatives' },
              }"
              style="text-decoration: none"
            >
              <b-card class="text-black work-box">
                <h5>{{ $t("navBar.label.salesRepresentatives") }}</h5>
                <!-- <span
                    >Duis et odio in metus malesuada tincidunt eget eu odio. Duis sed
                    velit turpis.
                  </span> -->
              </b-card>
            </b-link>
          </div>
          <div class="work-box-wrapper">
            <b-link
              :to="{
                name: 'Contact',
                params: { type: 'jobs' },
              }"
              style="text-decoration: none"
            >
              <b-card class="text-black work-box">
                <h5>{{ $t("navBar.label.jobs") }}</h5>
                <!-- <span
                    >Duis et odio in metus malesuada tincidunt eget eu odio. Duis sed
                    velit turpis.
                  </span> -->
              </b-card>
            </b-link>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "WorkWithUs",
  components: {},
  data() {
    return {
      selected: null,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/custom.scss";
.workWithUs {
  .workWithUs_bg {
    background-image: url("~@/assets/img/workWithUs_bg.jpg");
    background-position: top;
    background-repeat: no-repeat;
    background-color: #eff1f3;
    background-size: 100% 450px;
    padding-top: 250px;
    @media (max-width: 575px) {
      background-size: 122% 250px;
      padding-top: 100px;
    }

    .shadow {
      border: none;
    }
  }

  .sellBox {
    border: DodgerBlue 1px solid;
  }

  .sellBox h5 {
    color: DodgerBlue;
  }

  .IABox {
    border: DarkOrange 1px solid;
  }

  .IABox h5 {
    color: DarkOrange;
  }

  .FFBox {
    border: LimeGreen 1px solid;
  }

  .FFBox h5 {
    color: LimeGreen;
  }

  .CBBox {
    border: MediumSlateBlue 1px solid;
  }

  .CBBox h5 {
    color: MediumSlateBlue;
  }

  .SRBox {
    border: OrangeRed 1px solid;
  }

  .SRBox h5 {
    color: OrangeRed;
  }

  .jobsBox {
    border: Cyan 1px solid;
  }

  .jobsBox h5 {
    color: Cyan;
  }

  @media (max-width: 992px) {
    .directory {
      margin-left: 0;
    }
  }

  .custom-breadcrumb {
    height: 40px;
    background: #eff1f3;
    display: flex;
    align-items: center;
  }

  .bg-secondary {
    background: #eff1f3;
  }

  h3 {
    font-size: 34px !important;
    line-height: 48px !important;
    max-width: 580px;
    @media (max-width: 767px) {
      font-size: 28px !important;
      line-height: 40px !important;
      max-width: 100%;
    }
    @media (max-width: 575px) {
      font-size: 24px !important;
      line-height: 32px !important;
    }
  }

  .has-text {
    margin-bottom: 40px;
    @media (max-width: 575px) {
      margin-bottom: 24px;
    }
  }

  .work-box {
    height: 128px;
    border-color: transparent;
    border-bottom: 4px solid #006ac3;
    transition: 0.3s;
    border-radius: 4px;

    .card-body {
      padding: 10px 24px;
      display: flex;
      align-items: center;
    }

    h5 {
      transition: 0.3s;
      line-height: 36px;
      @media (max-width: 767px) {
        font-size: 20px;
      }
      @media (max-width: 575px) {
        font-size: 24px;
      }
    }

    &:hover {
      box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);
      border-width: 1px 1px 4px 1px;
      border-color: #006ac3;
      border-style: solid;

      h5 {
        color: #006ac3;
      }
    }
  }

  .box-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    column-gap: 24px;
    row-gap: 24px;
    padding-bottom: 64px;
    @media (max-width: 991px) {
      row-gap: 16px;
    }
    @media (max-width: 767px) {
      column-gap: 16px;
    }
  }

  .work-box-wrapper {
    width: calc((100% / 3) - 16px);
    @media (max-width: 991px) {
      width: calc((100% / 2) - 12px);
    }
    @media (max-width: 575px) {
      width: 100%;
    }
  }
}
</style>
